import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, List, ListItem, ListItemText, Paper, Menu, MenuItem, InputBase, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MicIcon from '@mui/icons-material/Mic';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function extractMessage(json) {
  if (json && json.content) {
    return json.content;
  }
  return null;
}

function ChatScreen() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [messages, setMessages] = useState([
    { id: 1, text: "Hola, soy ZicoAsis, tu asistente de Inteligencia Artificial. Estoy aquí para comprender cómo puedo ayudarte. ¿Cuáles son tus motivos de consulta?", sender: "ai" },
  ]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get('threadId');
  const idPsico = queryParams.get('idPsico');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!threadId || !idPsico) {
      alert('Para poder hablar con nuestro asistente, deberás completar el formulario inicial.');
      navigate('/');
    }
  }, [threadId, idPsico, navigate]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    handleClose();
  };

  const handleSendMessage = async () => {
    if (currentMessage.trim() === '' || !threadId) return;
    setIsSending(true);
    const newMessage = { id: messages.length + 1, text: currentMessage, sender: 'user' };
    setMessages([...messages, newMessage]);
    setCurrentMessage('');
    const loadingId = messages.length + 2;
    setMessages((prevMessages) => [...prevMessages, { id: loadingId, text: '...', sender: 'ai' }]);
    const loadingTexts = ['...', '..', '.'];
    let loadingIndex = 0;
    const loadingInterval = setInterval(() => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === loadingId ? { ...msg, text: loadingTexts[loadingIndex] } : msg
        )
      );
      loadingIndex = (loadingIndex + 1) % loadingTexts.length;
    }, 500);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CHAT_MESSAGES_ENDPOINT}`, { content: currentMessage, threadId: threadId });
      clearInterval(loadingInterval);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== loadingId)
      );
      const aiMessage = extractMessage(response.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: loadingId, text: aiMessage, sender: 'ai' },
      ]);
    } catch (error) {
      clearInterval(loadingInterval);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== loadingId)
      );
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleEndSession = async () => {
    try {
      const email = queryParams.get('email');
      const threadId = queryParams.get('threadId');

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/dossier/generate`, {
        conversation: messages,
        email: email,
        threadId: threadId,
        idPsico: idPsico,
      });

      navigate('/thank-you-page');
    } catch (error) {
      console.error('Error al enviar la conversación:', error);
      navigate('/thank-you-page');
    }
  };

  return (
    <div style={{ backgroundColor: darkMode ? '#1f1f1f' : '#f5f5f5', color: darkMode ? '#fff' : '#000', height: '100vh', display: 'flex', flexDirection: 'column', width: '100%' }}>
      <AppBar position="static" style={{ backgroundColor: darkMode ? '#2c2c2c' : '#ffffff', boxShadow: '0 4px 12px rgba(0,0,0,0.1)', width: '100%' }}>
        <Toolbar style={{ justifyContent: 'space-between', padding: '0 24px', width: '100%' }}>
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold', color: darkMode ? '#d3f36f' : '#74c2ef' }}>
            {idPsico === '7CFoRRQhlYbDvRNRJfn1KikllfP2' ? 'María del Pilar Ollero Berengena' : 'ZicoAsis'}
          </Typography>
          <div>
            <Button style={{ color: darkMode ? '#fff' : '#74c2ef' }} onClick={handleEndSession}>Finalizar conversación</Button>
            <IconButton color="inherit" onClick={handleClick}>
              <MoreVertIcon style={{ color: darkMode ? '#d3f36f' : '#74c2ef' }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => window.open('https://wa.link/8vjuk4', '_blank')}>Solicitar ayuda</MenuItem>
              <MenuItem onClick={toggleDarkMode}>{darkMode ? 'Modo Claro' : 'Modo Oscuro'}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ flexGrow: 1, overflow: 'auto', padding: '20px 0', width: '100%' }}>
        <List style={{ width: '100%', height: '100%', overflow: 'auto', padding: '0 20px' }}>
          {messages.map((message) => (
            <ListItem key={message.id} style={{ display: 'flex', justifyContent: message.sender === 'ai' ? 'flex-start' : 'flex-end', padding: '10px 0' }}>
              <Paper style={{
                padding: '12px 20px',
                maxWidth: '60%',
                width: 'fit-content',
                borderRadius: '20px',
                backgroundColor: message.sender === 'ai' ? (darkMode ? '#2c2c2c' : '#ffffff') : (darkMode ? '#d3f36f' : '#74c2ef'),
                color: message.sender === 'ai' ? (darkMode ? '#d3f36f' : '#74c2ef') : (darkMode ? '#000' : '#ffffff'),
                boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
                fontWeight: '500',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}>
                <ListItemText primary={message.text} />
              </Paper>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </div>
      <div style={{
        padding: '16px 24px',
        backgroundColor: darkMode ? '#2c2c2c' : '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: darkMode ? '1px solid #444' : '1px solid #e0e0e0',
        boxShadow: '0 -4px 10px rgba(0,0,0,0.1)',
        width: '100%',
      }}>
        <InputBase
          placeholder="Escribe un mensaje..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyPress={(e) => { if (e.key === 'Enter' && !isSending) handleSendMessage(); }}
          style={{
            flexGrow: 1,
            padding: '12px 20px',
            borderRadius: '30px',
            backgroundColor: darkMode ? '#424242' : '#f0f0f0',
            color: darkMode ? '#fff' : '#000',
            marginRight: '16px',
            boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
          }}
          inputProps={{ style: { color: darkMode ? '#fff' : '#000' }, disabled: isSending }}
        />
        <Tooltip title="Próximamente disponible" placement="top" arrow style={{ fontSize: '3rem' }}>
          <IconButton color="primary" style={{ backgroundColor: darkMode ? '#d3f36f' : '#74c2ef', color: darkMode ? '#000' : '#fff', borderRadius: '50%', padding: '12px', marginRight: '8px' }}>
            <MicIcon style={{ fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
        <IconButton color="primary" style={{ backgroundColor: darkMode ? '#d3f36f' : '#74c2ef', color: darkMode ? '#000' : '#fff', borderRadius: '50%', padding: '12px' }} onClick={handleSendMessage} disabled={isSending}>
          <SendIcon style={{ fontSize: '2rem' }} />
        </IconButton>
      </div>
    </div>
  );
}

export default ChatScreen;