import React from 'react';
import { Dialog, DialogTitle, DialogContent, Card, CardContent, CardActions, Button, Typography, Paper } from '@mui/material';
import { css } from '@emotion/css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Confetti from 'react-confetti';

const PlanDialog = ({ open, onClose, plan, waLink, stripeStarterUrl, stripeProfessionalUrl }) => (
  <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
    <DialogTitle className={css`
      color: #37474F;
      font-weight: 600;
    `}>Gestionar Suscripción</DialogTitle>
    <DialogContent>
      <div
        className={css`
          display: flex;
          justify-content: space-around;
          align-items: stretch;
          gap: 20px;
          flex-wrap: wrap;
        `}
      >
        <Card className={css`
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: #fafafa;
          transition: all 0.3s ease;
          flex: 1;
          min-width: 250px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          }
        `}>
          <CardContent>
            <Typography variant="h6" className={css`
              font-weight: 600;
              color: #37474F;
            `}>Basic</Typography>
            <Typography variant="h4" className={css`
              color: #4CAF50;
              font-weight: 700;
              margin: 10px 0;
            `}>
              Gratis
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 10px;
            `}>
              Incluye:
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 10px;
            `}>
              - 1 informe de prueba
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
            `}>
              - Soporte básico
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              disabled={plan === 'free'}
              onClick={() => window.open(waLink, '_blank')}
              className={css`
                color: ${plan === 'free' ? '#757575' : '#1976d2'};
                border-color: ${plan === 'free' ? '#E0E0E0' : '#1976d2'};
                width: 100%;
              `}
            >
              {plan === 'free' ? 'Plan Actual' : 'Cambiar a Basic'}
            </Button>
          </CardActions>
        </Card>

        <Card className={css`
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: #fafafa;
          transition: all 0.3s ease;
          flex: 1;
          min-width: 250px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          }
        `}>
          <CardContent>
            <div className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
            `}>
              <Typography variant="h6" className={css`
                font-weight: 600;
                color: #37474F;
              `}>Starter</Typography>
              {plan === 'starter' && (
                <Typography variant="subtitle2" className={css`
                  background-color: #4CAF50;
                  color: white;
                  padding: 4px 8px;
                  border-radius: 4px;
                  font-weight: 600;
                `}>
                  Plan Actual
                </Typography>
              )}
            </div>
            <Typography variant="h4" className={css`
              color: #1976d2;
              font-weight: 700;
              margin: 10px 0;
            `}>
              <span className={css`
                text-decoration: line-through;
                color: #999;
                margin-right: 10px;
                font-size: 0.8em;
              `}>40 €</span>
              20 €
            </Typography>
            <Typography variant="body2" className={css`
              color: #f44336;
              font-weight: 600;
              margin-bottom: 10px;
            `}>
              Oferta de lanzamiento: 50% dto.
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 10px;
            `}>
              Incluye:
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 5px;
            `}>
              - Zicofyers ilimitados
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 5px;
            `}>
              - 5 informes mensuales
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
            `}>
              - Soporte básico
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(stripeStarterUrl, '_blank')}
              className={css`
                background-color: #1976d2;
                color: #fff;
                width: 100%;
                &:hover {
                  background-color: #1565c0;
                }
              `}
            >
              Elegir Starter
            </Button>
          </CardActions>
        </Card>

        <Card className={css`
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: #fafafa;
          transition: all 0.3s ease;
          flex: 1;
          min-width: 250px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          }
        `}>
          <CardContent>
            <div className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
            `}>
              <Typography variant="h6" className={css`
                font-weight: 600;
                color: #37474F;
              `}>Professional</Typography>
              {plan === 'professional' && (
                <Typography variant="subtitle2" className={css`
                  background-color: #4CAF50;
                  color: white;
                  padding: 4px 8px;
                  border-radius: 4px;
                  font-weight: 600;
                `}>
                  Plan Actual
                </Typography>
              )}
            </div>
            <Typography variant="h4" className={css`
              color: #f44336;
              font-weight: 700;
              margin: 10px 0;
            `}>
              <span className={css`
                text-decoration: line-through;
                color: #999;
                margin-right: 10px;
                font-size: 0.8em;
              `}>60 €</span>
              30 €
            </Typography>
            <Typography variant="body2" className={css`
              color: #f44336;
              font-weight: 600;
              margin-bottom: 10px;
            `}>
              Oferta de lanzamiento: 50% dto.
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 10px;
            `}>
              Incluye:
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 5px;
            `}>
              - Zicofyers ilimitados
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 5px;
            `}>
              - 10 informes mensuales
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
              margin-bottom: 5px;
            `}>
              - Personalización de informes
            </Typography>
            <Typography variant="body2" className={css`
              color: #607D8B;
            `}>
              - Soporte dedicado
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.open(stripeProfessionalUrl, '_blank')}
              className={css`
                background-color: #f44336;
                color: #fff;
                width: 100%;
                &:hover {
                  background-color: #d32f2f;
                }
              `}
            >
              Elegir Professional
            </Button>
          </CardActions>
        </Card>

        {plan === 'alphatester' && (
          <Card className={css`
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            background-color: #fafafa;
            transition: all 0.3s ease;
            flex: 1;
            min-width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            }
          `}>
            <CardContent>
              <div className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
              `}>
                <Typography variant="h6" className={css`
                  font-weight: 600;
                  color: #37474F;
                `}>Alpha Tester</Typography>
                <Typography variant="subtitle2" className={css`
                  background-color: #4CAF50;
                  color: white;
                  padding: 4px 8px;
                  border-radius: 4px;
                  font-weight: 600;
                `}>
                  Plan Actual
                </Typography>
              </div>
              <Typography variant="h4" className={css`
                color: #4CAF50;
                font-weight: 700;
                margin: 10px 0;
              `}>
                Gratis
              </Typography>
              <Typography variant="body2" className={css`
                color: #607D8B;
                margin-bottom: 10px;
              `}>Incluye:</Typography>
              <Typography variant="body2" className={css`
                color: #607D8B;
                margin-bottom: 5px;
              `}>- Todas las funciones de los planes de pago</Typography>
              <Typography variant="body2" className={css`
                color: #607D8B;
              `}>- Soporte prioritario por WhatsApp</Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => window.open('https://wa.link/r2eukq', '_blank')}
                className={css`
                  color: #4CAF50;
                  border-color: #4CAF50;
                  width: 100%;
                  &:hover {
                    background-color: rgba(76, 175, 80, 0.04);
                  }
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <WhatsAppIcon style={{ marginRight: '8px' }} />
                Contactar a Agus
              </Button>
            </CardActions>
          </Card>
        )}
      </div>
      <Paper 
        elevation={1}
        className={css`
          margin-top: 20px;
          padding: 20px;
          background-color: #FFF9C4;
          text-align: center;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
        `}
      >
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={100}
          opacity={0.6}
        />
        <Typography variant="h6" component="div" gutterBottom className={css`
          color: #FF6F00;
          font-weight: bold;
        `}>
          🎊 Oferta Especial 🎊
        </Typography>
        <Typography variant="h5" component="div" className={css`
          color: #FF3D00;
          font-weight: bold;
          margin: 10px 0;
        `}>
          Código: ZICOASISLAUNCH
        </Typography>
        <Typography variant="body2" className={css`
          color: #FF6F00;
        `}>
          Disfruta de un 50% de descuento al suscribirte
        </Typography>
      </Paper>
    </DialogContent>
  </Dialog>
);

export default PlanDialog;