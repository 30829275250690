import React from 'react';
import { css } from "@emotion/css";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const err404 = () => {
  const navigate = useNavigate();

  return (
    <Box
      className={css`
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6);
        background-size: 180% 180%;
        animation: gradient-animation 24s ease infinite;

        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `}
    >
      <Typography variant="h1" component="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Página no encontrada
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lo sentimos, la página que estás buscando no existe.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          color: '#333',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
        }}
      >
        Volver al inicio
      </Button>
    </Box>
  );
};

export default err404;
