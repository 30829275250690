import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { css } from '@emotion/css';
import ReactGA from 'react-ga4';

const PatientsTable = ({ filteredPatients, loadingReport, reportGenerated, handleGenerateReport }) => {
  const handleGenerateReportClick = (email) => {
    ReactGA.event({
      category: 'Conversión',
      action: 'Informes generados',
      label: 'Botón Generar Informe'
    });
    handleGenerateReport(email);
  };

  return (
    <TableContainer
      component={Paper}
      className={css`
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 20px;
      `}
    >
      <Table
        stickyHeader
        className={css`
          text-align: center;
        `}
      >
        <TableHead className={css`
          background-color: #f1f1f1;
        `}>
          <TableRow>
            <TableCell align="center" className={css`
              color: #455A64;
              font-weight: 600;
            `}>Nombre Completo</TableCell>
            <TableCell align="center" className={css`
              color: #455A64;
              font-weight: 600;
            `}>Edad</TableCell>
            <TableCell align="center" className={css`
              color: #455A64;
              font-weight: 600;
            `}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPatients.map((patient) => (
            <TableRow key={patient.email} className={css`
              &:hover {
                background-color: #f5f5f5;
              }
            `}>
              <TableCell align="center">{patient.fullname}</TableCell>
              <TableCell align="center">{patient.age}</TableCell>
              <TableCell align="center">
                {loadingReport[patient.email] ? (
                  <CircularProgress size={24} />
                ) : reportGenerated[patient.email] ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircle color="success" />
                    <span style={{ marginLeft: '8px' }}>Informe listo, revisa tu casilla</span>
                  </div>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleGenerateReportClick(patient.email)}
                      className={css`
                        background-color: #4caf50;
                        font-weight: 600;
                        &:hover {
                          background-color: #388e3c;
                        }
                        margin-right: 10px;
                      `}
                    >
                      Generar Informe
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open('https://forms.gle/YE27HEbw28q2Sg859', '_blank')}
                      className={css`
                        font-weight: 600;
                      `}
                    >
                      Calidad del informe
                    </Button>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientsTable;