import React, { useState, useEffect } from 'react';
import { DialogTitle, Dialog, TextField, Button, Box, InputAdornment, IconButton, Snackbar, Alert, Grid, DialogActions, DialogContent, Typography, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { auth, provider } from '../firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

function LoginForm({ switchToRegister, setUserId }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { setIdentity } = useTrackingCode(); // Inicializar el hook para el seguimiento

  useEffect(() => {
    if (email) {
      setIdentity(email); // Identificar al usuario con el email
    }
  }, [email, setIdentity]);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setResetEmail('');
    setResetMessage('');
  };
  
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
    
      const idPsico = user.uid;
      localStorage.setItem('idPsico', idPsico);
      setUserId(idPsico);
      
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/psico/psico-data`;
      
      const response = await axios.get(apiUrl, {
        params: { idPsico }
      });
      
      const { fullname, plan, informesGenerados, informeLimite } = response.data;
      
      localStorage.setItem('userName', fullname);
      localStorage.setItem('plan', plan);
      localStorage.setItem('informesGenerados', informesGenerados || 0);
      localStorage.setItem('informeLimite', informeLimite || 1);

      // Enviar datos de login a HubSpot
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hubspot/contact`, {
        email,
        source: 'login',
      });
      
      navigate('/psicologo');
    } catch (error) {
      setErrorMessage(`Error en la autenticación: ${error.message}`);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSendResetEmail = async () => {
    if (!resetEmail) {
      setResetMessage('Por favor, ingrese su correo electrónico.');
      return;
    }
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Si el correo existe, hemos enviado un enlace para restablecer tu contraseña.');
    } catch (error) {
      setResetMessage('Error al enviar el correo de restablecimiento. Verifica el correo e inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const idPsico = user.uid;
      localStorage.setItem('idPsico', idPsico);
      setUserId(idPsico);
      
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/psico/psico-data`;
      
      const response = await axios.get(apiUrl, {
        params: { idPsico }
      });
      
      const { fullname, plan, email, informesGenerados, informeLimite } = response.data;
      
      localStorage.setItem('userName', fullname);
      localStorage.setItem('plan', plan);
      localStorage.setItem('informesGenerados', informesGenerados || 0);
      localStorage.setItem('informeLimite', informeLimite || 1);

      // Enviar datos de login a HubSpot
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hubspot/contact`, {
        email,
        source: 'login',
      });
      
      navigate('/psicologo');
    } catch (error) {
      setErrorMessage('Error al iniciar sesión con Google: ' + error.message);
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };  

  const handleForgotPassword = async () => {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email);
      alert('Se ha enviado un enlace de restablecimiento de contraseña a tu correo electrónico.');
    } catch (error) {
      setErrorMessage('Error al enviar el correo de restablecimiento');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box component="form">
      <Box
        component="img"
        sx={{
          height: 60,
          mb: 2,
        }}
        alt="Logo"
        src="/download-1@2x.png"
      />
      <TextField
        label="Correo"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
        disabled={isLoading}
      />
      <TextField
        label="Contraseña"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordVisibility} edge="end" disabled={isLoading}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={{ marginBottom: 2 }}
        disabled={isLoading}
      />
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Button onClick={handleLogin} variant="contained" color="primary" fullWidth disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Iniciar Sesión'}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={handleGoogleSignIn} variant="contained" fullWidth startIcon={<GoogleIcon />}
            sx={{
              backgroundColor: '#4285F4',
              color: 'white',
              '&:hover': {
                backgroundColor: '#357ae8',
              },
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Google'}
          </Button>
        </Grid>
      </Grid>
      <Button onClick={handleOpenDialog} variant="text" color="secondary" sx={{ mt: 2 }} disabled={isLoading}>
        Olvidé mi contraseña
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}>
          Restablecer Contraseña
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {'\n'}
            {'\n'}
            Ingrese su correo electrónico y le enviaremos un enlace para restablecer su contraseña.
          </Typography>
          <TextField
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            sx={{ mb: 2 }}
            disabled={isLoading}
          />
          {resetMessage && (
            <Alert severity={resetMessage.includes('Error') ? 'error' : 'info'} sx={{ mb: 2 }}>
              {resetMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2, backgroundColor: '#f5f5f5', borderTop: '1px solid #e0e0e0' }}>
          <Button onClick={handleCloseDialog} color="secondary" disabled={isLoading}>
            Cancelar
          </Button>
          <Button onClick={handleSendResetEmail} variant="contained" color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Enviar enlace'}
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => switchToRegister()} fullWidth>
        ¿No tienes cuenta? Regístrate
      </Button> 
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default LoginForm;