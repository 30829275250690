import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';

function UserTermsConditions({ showTerms, handleTermsClose, handleTermsAccept, userData }) {
  return (
    <Dialog open={showTerms} onClose={handleTermsClose} maxWidth="md" fullWidth>
      <DialogTitle>Términos y Condiciones</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Documento de Consentimiento Informado para la Participación en el Proyecto de Inteligencia Artificial ZicoAsis</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Introducción:</strong><br />
            Este documento sirve para informarle sobre su participación en el proyecto de inteligencia artificial ZicoAsistente (también referido como "ZicoAsis" o "ZicoAsis by Zicofy"), desarrollado por Zicofy Software S.L. (NIF B19932045). Este proyecto tiene como objetivo desarrollar un sistema de inteligencia artificial capaz de brindar orientación psicológica inicial a personas antes de sus sesiones regulares con su terapeuta, informando al terapeuta también de los resultados a través de un reporte generado con un modelo propio de Inteligencia Artificial. Su participación es voluntaria y este documento detalla cómo se utilizarán sus datos personales, quién tendrá acceso a ellos y sus derechos como participante.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Descripción del Proyecto:</strong><br />
            El proyecto ZicoAsistente consiste en el desarrollo de una aplicación web en React que ofrece un servicio de orientación psicológica inicial a través de inteligencia artificial. Esta aplicación está compuesta por tres módulos principales:
          </Typography>
          <Typography variant="body2" component="div">
            <ol>
              <li>Formulario de Ingreso: El usuario comienza completando un formulario en la plataforma, en el que proporciona datos personales y de sus antecedentes médicos y tratamientos previos. Esta información se almacena de manera segura en nuestra base de datos en Firebase Firestore, un sistema de almacenamiento NoSQL que garantiza la protección y la organización adecuada de los datos.</li>
              <li>Interacción con el Asistente de IA: Utilizando la información recolectada, se inicia una interacción inicial con nuestro asistente de inteligencia artificial, desarrollado a través de las APIs de OpenAI. Durante esta interacción, el usuario puede chatear en tiempo real con el asistente, que está diseñado para proporcionar orientación inicial, responder preguntas y guiar al potencial paciente en la elaboración de su motivo de consulta, sin ofrecer soluciones terapéuticas.</li>
              <li>Generación y Envío de Informes: Al finalizar el chat inicial, el usuario presiona el botón de "Finalizar Interacción". En ese momento, toda la conversación se guarda en nuestra base de datos. Posteriormente, un segundo asistente, también basado en inteligencia artificial, analiza la conversación y genera un informe detallado en formato PDF. Este informe incluye un resumen del motivo de consulta, proporcionando mayor claridad para la planificación del tratamiento, y se envía automáticamente al psicólogo registrado en el sistema mediante Sendgrid, asegurando que el profesional tenga acceso a los resultados para su revisión y seguimiento.</li>
            </ol>
          </Typography>
          <Typography variant="body2" paragraph>
            Este proyecto tiene como objetivo principal ofrecer una herramienta de apoyo para la práctica psicológica, facilitando la comunicación entre pacientes y psicólogos y proporcionando un recurso adicional para la planificación del tratamiento fuera de las interacciones presenciales.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Datos Personales Recolectados:</strong>
          </Typography>
          <ul>
            <li>Datos identificativos (nombre, edad, etc.).</li>
            <li>Datos de contacto (correo electrónico, teléfono).</li>
            <li>Antecedentes médicos y tratamientos previos.</li>
            <li>Interacciones con el sistema de inteligencia artificial.</li>
          </ul>
          <Typography variant="body2" paragraph>
            <strong>Uso de los Datos:</strong><br />
            Los datos recopilados, almacenados con verificaciones de seguridad y cifrados, serán utilizados exclusivamente para los siguientes fines:
          </Typography>
          <ul>
            <li>Gestión de perfiles: Manejo y almacenamiento de perfiles de psicólogos y pacientes, incluidos identificadores únicos, nombres, género, edad y detalles sobre las credenciales y especializaciones de los psicólogos.</li>
            <li>Vinculación de pacientes y psicólogos: Asignación de pacientes a sus respectivos psicólogos mediante identificadores únicos que faciliten el seguimiento y la atención personalizada.</li>
            <li>Creación de informes (Dossiers): Generación de informes detallados para psicólogos que incluyen un resumen del motivo de consulta, mejorando así la planificación del tratamiento y la comprensión del paciente.</li>
            <li>Mejora del sistema: Análisis de datos para mejorar continuamente la funcionalidad del sistema, asegurando una interacción efectiva y precisa entre pacientes y la inteligencia artificial.</li>
          </ul>
          <Typography variant="body2" paragraph>
            Cada uno de estos usos está diseñado para garantizar que el sistema proporcione un soporte eficaz y eficiente tanto para los psicólogos como para sus pacientes, manteniendo al mismo tiempo la integridad y confidencialidad de los datos personales.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Acceso a los Datos:</strong><br />
            Sus datos personales serán accesibles por:
          </Typography>
          <ul>
            <li>El equipo de Zicofy Software S.L. y sus colaboradores externos: Tanto el equipo interno de Zicofy Software S.L. como sus colaboradores externos tendrán acceso a sus datos para fines de mantenimiento, mejora del sistema, y para asegurar que el servicio funcione correctamente y se adapte a las necesidades de los usuarios. Todos los miembros del equipo, incluidos los colaboradores externos, están sujetos a acuerdos estrictos de no divulgación y confidencialidad (NDA) para proteger y asegurar la privacidad de los datos. Es importante destacar que Zicofy Software S.L. no está obligada legalmente a informar a los usuarios sobre la entrada o salida de colaboradores externos.</li>
            <li>Investigadores autorizados: Profesionales que colaboran externamente con Zicofy Software S.L. y que no se encuentran en relación de dependencia con la empresa, tendrán acceso a los datos únicamente para fines académicos y de investigación relacionados con este proyecto. El acceso será regulado y supervisado para garantizar que se utilice adecuadamente dentro de los marcos éticos y legales. Estos investigadores también están sujetos a acuerdos estrictos de confidencialidad. La colaboración con estos investigadores se justifica legalmente mediante la relación comercial que se demuestra a través de facturas, no siendo necesaria una relación contractual directa.</li>
            <li>Psicólogos asignados a cada paciente: Cada psicólogo tendrá acceso a los datos de sus pacientes asignados, incluyendo perfiles y resúmenes del motivo de consulta, para facilitar la planificación del tratamiento personalizado y monitorizar el progreso del paciente. Los psicólogos están obligados a adherirse a los más altos estándares de confidencialidad y a acuerdos de no divulgación específicos que garantizan la seguridad de la información compartida.</li>
          </ul>
          <Typography variant="body2" paragraph>
            Este control de acceso está diseñado para proteger la privacidad y seguridad de los datos personales, mientras se permite el uso efectivo de los mismos para el tratamiento y la investigación. Todos los involucrados en el manejo de datos personales están comprometidos contractualmente a respetar y mantener la confidencialidad y seguridad de la información.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Seguridad y Protección de los Datos:</strong><br />
            Implementamos medidas de seguridad técnicas y organizativas para proteger sus datos personales contra el acceso no autorizado, la alteración, la divulgación o la destrucción. Estas medidas incluyen:
          </Typography>
          <ul>
            <li>Cifrado de datos en reposo y en tránsito: Utilizamos tecnologías de cifrado avanzadas para asegurar que todos los datos almacenados y transmitidos estén protegidos de forma segura. El cifrado en tránsito se maneja a través de TLS (Transport Layer Security) y otros protocolos que aseguran que los datos que se mueven entre dispositivos y nuestros servidores estén seguros.</li>
            <li>Controles de acceso: Establecemos controles estrictos de acceso que limitan quién puede ver y utilizar los datos personales. Solo el personal autorizado tiene acceso a información sensible, y este acceso está basado en la necesidad de conocer para cumplir con sus funciones laborales.</li>
            <li>Auditorías y monitoreo de seguridad: Realizamos auditorías de seguridad regulares y monitoreamos continuamente nuestra infraestructura para detectar y responder rápidamente a cualquier actividad sospechosa o no autorizada.</li>
            <li>Formación en seguridad para empleados: Todos los empleados que tienen acceso a datos personales reciben formación regular sobre las mejores prácticas de seguridad y privacidad de datos para garantizar que entiendan cómo manejar la información de manera segura.</li>
          </ul>
          <Typography variant="body2" paragraph>
            Estas medidas están diseñadas para proteger la integridad y la privacidad de sus datos y para cumplir con las regulaciones de protección de datos vigentes.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Retiro del Consentimiento:</strong><br />
            Usted tiene derecho a retirar su consentimiento en cualquier momento. Si decide retirar su consentimiento, puede hacerlo enviando una solicitud por escrito a ayuda@zicofy.com o a través de la interfaz de usuario proporcionada en nuestra plataforma, si está disponible. Al recibir su solicitud, procederemos a:
          </Typography>
          <ul>
            <li>Eliminar todos los datos personales asociados a su cuenta de nuestros sistemas de forma segura y conforme a la legislación vigente sobre protección de datos. Esto incluye cualquier dato almacenado en nuestras bases de datos y cualquier copia de seguridad en la que estos datos puedan estar presentes.</li>
            <li>Cese del tratamiento de sus datos: Detendremos inmediatamente todo procesamiento de sus datos personales que requiera su consentimiento.</li>
            <li>Notificación a terceros: En caso de que hayamos compartido sus datos con terceros, les notificaremos su decisión para asegurar que también cesen el procesamiento de sus datos y los eliminen según lo requiera la ley.</li>
          </ul>
          <Typography variant="body2" paragraph>
            Es importante tener en cuenta que el retiro de su consentimiento no afecta la legalidad del tratamiento basado en el consentimiento previo a su retirada. Además, ciertos datos pueden ser retenidos por nosotros para cumplir con nuestras obligaciones legales o para resolver disputas y hacer cumplir nuestros acuerdos.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Derechos del Participante:</strong><br />
            Tiene derecho a acceder, rectificar, eliminar y portar sus datos personales, así como el derecho de limitar u oponerse a su tratamiento. Para ejercer estos derechos, puede contactarnos a través de ayuda@zicofy.com.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Contacto:</strong><br />
            Para cualquier pregunta relacionada con su participación en este proyecto o sus datos personales, no dude en contactar a Agustín Hernán Rodríguez (NIE Z2188200P), responsable del proyecto y oficial de protección de datos en Zicofy Software S.L a agus@zicofy.com.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Consentimiento:</strong><br />
            Yo, declaro que he leído y comprendido la información proporcionada en este documento. Consiento voluntariamente participar en el proyecto ZicoAsistente bajo los términos aquí descritos.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTermsClose}>Cancelar</Button>
        <Button onClick={handleTermsAccept} variant="contained" color="primary">
          He leído y acepto
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserTermsConditions;