import React from 'react';
import { Paper, Typography, Button } from '@mui/material';
import { css } from '@emotion/css';

const WelcomeMessage = ({ handleLogout, waLink, handlePlanDialogOpen, handleCopyLink, userName, plan, filteredPatients, planLimits, informesGenerados, informeLimite }) => (
  <Paper
    className={css`
      width: 100%;
      max-width: 1200px;
      margin-bottom: 30px;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      padding: 20px;
    `}
  >
    <Typography variant="h5" align="center" gutterBottom className={css`
      color: #37474F;
      font-weight: 700;
      margin-bottom: 20px;
    `}>
      <strong>¡Te damos la bienvenida al <strong>Zico</strong>Asis!</strong>
    </Typography>

    <Typography variant="body1" align="justify" gutterBottom className={css`
      color: #455A64;
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 1.6;
      padding: 0 20px;
    `}>
      En este portal podrás gestionar tus <strong>informes</strong> y <strong>Zicofyers</strong> (consultantes) que hayan completado una conversación.
      <br /><br />
      <strong>(1)</strong> Comparte con tu <strong>Zicofyer</strong> (consultante) el enlace para que pueda iniciar su conversación con la IA. Puedes copiar el enlace pulsando el botón <em>"Copiar Enlace para <strong>Zicofyers</strong>"</em>.
      <br />
      <strong>(2)</strong> Tu <strong>Zicofyer</strong> (consultante) debe acceder al enlace, que ya tendrá pre-cargado tu <strong>ID de <strong>Zico</strong></strong> (psicólogo), y luego de cargar sus datos podrá comenzar su conversación con la IA.
      <br />
      <strong>(3)</strong> Una vez complete su conversación, verás disponible la opción de <em>"Generar Informe"</em> al lado de cada <strong>Zicofyer</strong> (consultante) para recibir por correo electrónico el documento en PDF.
      <br /><br />
      Si esta plataforma te resulta útil, nos ayudaría mucho que puedas contestar nuestra encuesta de <strong>"Calidad del Informe"</strong> a la que puedes acceder con el botón que se encuentra a un lado del botón <em>"Generar Informe"</em>. Eso nos ayuda a mejorar la herramienta y poder darle mayor precisión y utilidad a la hora de generar informes.
    </Typography>

    <Typography variant="h6" align="center" gutterBottom className={css`
      color: #37474F;
      font-weight: 700;
      margin-bottom: 20px;
    `}>
      ID <strong>Zico</strong>: {localStorage.getItem('idPsico')}
    </Typography>

    <Typography variant="h6" gutterBottom className={css`
      color: #455A64;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: center;
    `}>
      Cantidad de informes: {informesGenerados}/{informeLimite}
    </Typography>

    <div
      className={css`
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 10px;
      `}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={handleLogout}
        className={css`
          padding: 12px 24px;
          font-weight: 600;
          border-radius: 8px;
        `}
      >
        Cerrar Sesión
      </Button>
      <Button variant="outlined" color="secondary" onClick={() => window.open(waLink, '_blank')} className={css`
        padding: 12px 24px;
        font-weight: 600;
        border-radius: 8px;
      `}>
        Necesito ayuda
      </Button>
      <Button variant="contained" color="primary" onClick={handlePlanDialogOpen} className={css`
        padding: 12px 24px;
        background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
        box-shadow: 0px 3px 5px 2px rgba(33, 203, 243, .3);
        font-weight: 600;
        border-radius: 8px;
      `}>
        Administrar Plan
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCopyLink}
        className={css`
          padding: 12px 24px;
          font-weight: 600;
          border-radius: 8px;
        `}
      >
        Copiar enlace para Zicofyers
      </Button>
    </div>
  </Paper>
);

export default WelcomeMessage;