import LoginForm1 from "../components/LoginForm1";
import { css } from "@emotion/css";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();

  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6);
        background-size: 180% 180%;
        animation: gradient-animation 24s ease infinite;

        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `}
    >
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        className={css`
          position: absolute;
          top: 20px;
          left: 20px;
          background-color: rgba(255, 255, 255, 0.7);
          color: #333;
          &:hover {
            background-color: rgba(255, 255, 255, 0.9);
          }
        `}
      >
        Volver al inicio
      </Button>
        <LoginForm1 />
    </div>
  );
};

export default LoginForm;
