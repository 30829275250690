import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";

import createGlobalStyle from "./global";

const muiTheme = createTheme();

const basename = process.env.PUBLIC_URL || "/";

const container = document.getElementById("root");
const root = createRoot(container);
createGlobalStyle();

root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();