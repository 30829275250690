import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Container, Box, Typography, Snackbar, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import UserTermsConditions from './UserTermsConditions';

const LoginForm1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    idPsico: '',
    age: '',
    scolarity: '',
    work: '',
    family: '',
    civil_state: '',
    antecedentes: '',
    fullname: '',
    sex: '',
  });
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [idPsicoFromLink, setIdPsicoFromLink] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idPsico = params.get('idPsico');
    if (idPsico) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        idPsico: idPsico,
      }));
      setIdPsicoFromLink(true);
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextStep = async () => {
    setLoading(true);
    if (step === 1 && !validateEmail(formData.email)) {
      setErrors({ email: 'Por favor, ingresa un correo electrónico válido.' });
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }
    if (step === 1 && !idPsicoFromLink && formData.idPsico.length < 1) {
      setErrors({ idPsico: 'El ID es requerido.' });
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }
    setErrors({});
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_VALIDATE_CODE_ENDPOINT}`, {
        email: formData.email,
        idPsico: formData.idPsico,
        fullname: formData.fullname,
      });
      console.log('Datos validados:', response.data);
      if (response.data) {
        setOpenTerms(true);
      }
    } catch (error) {
      console.error('Error al validar los datos:', error);
      setErrors({ server: 'Error al validar los datos. Por favor, inténtalo de nuevo.' });
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  const handleAcceptTerms = () => {
    setOpenTerms(false);
    setStep(2);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateEmail(formData.email)) {
      setErrors({ email: 'Por favor, ingresa un correo electrónico válido.' });
      setOpenSnackbar(true);
      return;
    }
    setErrors({});
    console.log('Enviando datos:', formData);
  
    const endpoint = step === 2
      ? `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_REGISTER_USER_ENDPOINT}`
      : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PATIENT_STEP1_ENDPOINT}`;
  
    try {
      const response = await axios.post(endpoint, formData);
      console.log('Datos enviados:', response.data);
      if (step === 2) {
        console.log('Usuario registrado con éxito');
        const { threadId } = response.data;
        navigate(`/chat?threadId=${threadId}&email=${encodeURIComponent(formData.email)}&idPsico=${encodeURIComponent(formData.idPsico)}`);
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      setErrors({ server: 'Error al enviar los datos. Por favor, inténtalo de nuevo.' });
      setOpenSnackbar(true);
    }
    setLoading(false);
  };
  
  const handleIdPsicoChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 30) {
      handleChange(e);
    }
  };
  
  const handleIdPsicoBlur = () => {
    if (!idPsicoFromLink && formData.idPsico.length < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        idPsico: 'El ID es requerido.',
      }));
      setOpenSnackbar(true);
    } else {
      setErrors((prevErrors) => {
        const { idPsico, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container
      component="main"
      maxWidth={step === 1 ? "xs" : "sm"}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          backgroundColor: 'white',
          borderRadius: 1,
          boxShadow: 3,
          width: '100%',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        {formData.idPsico === '7CFoRRQhlYbDvRNRJfn1KikllfP2' ? (
            <Typography component="h1" variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
              Consulta inicial con
              <Typography component="span" variant="h5" sx={{ display: 'block', fontWeight: 'bold' }}>
                María del Pilar Ollero Berengena
              </Typography>
            </Typography>
          ) : (
          <Box
            component="img"
            sx={{
              height: 60,
              mb: 2,
            }}
            alt="Logo"
            src="/download-1@2x.png"
          />
        )}
        <Typography component="h1" variant="h5">
          {step === 1 ? '1° Paso' : '2° Paso'}
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 2 }}>
          {step === 1
            ? 'Antes de comenzar la conversación con el ZicoAsis, necesitamos saber tus datos para construir tu perfil y que la IA sea personalizada para ti.'
            : 'Ya casi estamos, solo algunas preguntas más.'}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }} onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="fullname"
                label="Nombre completo"
                name="fullname"
                autoComplete="fullname"
                autoFocus
                value={formData.fullname}
                onChange={handleChange}
                error={!!errors.fullname}
                helperText={errors.fullname}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              {!idPsicoFromLink && (
                <TextField
                  disabled={loading}
                  margin="normal"
                  required
                  fullWidth
                  name="idPsico"
                  label="ID de tu Zico"
                  type="text"
                  id="idPsico"
                  autoComplete="current-password"
                  value={formData.idPsico}
                  onChange={handleIdPsicoChange}
                  onBlur={handleIdPsicoBlur}
                  error={!!errors.idPsico}
                  helperText={errors.idPsico}
                />
              )}
            </>
          )}
          {step > 1 && (
            <>
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="age"
                label="¿Cuál es tu edad?"
                name="age"
                autoComplete="age"
                autoFocus
                value={formData.age}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="sex"
                label="¿Cuál es tu género?"
                name="sex"
                autoComplete="sex"
                autoFocus
                value={formData.sex}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="scolarity"
                label="¿Cuál es tu nivel de escolaridad?"
                name="scolarity"
                autoComplete="scolarity"
                autoFocus
                value={formData.scolarity}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="work"
                label="¿En qué trabajas?"
                name="work"
                autoComplete="work"
                autoFocus
                value={formData.work}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="family"
                label="¿Con quién vives?"
                name="family"
                autoComplete="family"
                autoFocus
                value={formData.family}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="civil_state"
                label="¿Cuál es tu estado civil?"
                name="civil_state"
                autoComplete="civil_state"
                autoFocus
                value={formData.civil_state}
                onChange={handleChange}
              />
              <TextField
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="antecedentes"
                label="¿Tienes algún antecedente médico?"
                name="antecedentes"
                autoComplete="antecedentes"
                autoFocus
                value={formData.antecedentes}
                onChange={handleChange}
              />
            </>
          )}
          {step === 1 ? (
              <Button
              disabled={loading}
              startIcon={loading && <CircularProgress size={24} />}
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#d3f36f', color: 'black' }}
              onClick={handleNextStep}
            >
              Avanzar a la segunda sección
            </Button>
          ) : (
            <>
              <Button
                disabled={loading}
                startIcon={loading && <CircularProgress size={24} />}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#d3f36f', color: 'black' }}
              >
                Finalizar formulario y comenzar conversación
              </Button>
              <Button
                disabled={loading}
                startIcon={loading && <CircularProgress size={24} />}
                type="button"
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mb: 2 }}
                onClick={handlePreviousStep}
              >
                Volver al paso 1
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errors.email || errors.idPsico || errors.server}
        </Alert>
      </Snackbar>
      <UserTermsConditions
        showTerms={openTerms}
        handleTermsClose={handleCloseTerms}
        handleTermsAccept={handleAcceptTerms}
        userData={formData}
      />
    </Container>
  );
};

export default LoginForm1;