import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/css';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const UserType = () => {
  const navigate = useNavigate();

  return (
    <div
      className={css`
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6);
        background-size: 180% 180%;
        animation: gradient-animation 24s ease infinite;

        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `}
    >
      <h1
        className={css`
          color: #37474f;
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 40px;
          text-align: center;
        `}
      >
        ¿Quién eres?
      </h1>
      <div
        className={css`
          display: flex;
          gap: 40px;
          justify-content: center;
        `}
      >
        <div
          className={css`
            position: relative;
          `}
        >
          <Tooltip title="Un Zico es un psicólogo" placement="top">
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                color: '#37474f',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                padding: '4px',
              }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <button
            onClick={() => navigate('/login/login')}
            className={css`
              padding: 20px 40px;
              font-size: 1.5rem;
              font-weight: bold;
              color: #ffffff;
              background-color: #74c2ef;
              border: none;
              border-radius: 12px;
              box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
              text-transform: uppercase;

              &:hover {
                background-color: #56a9d4;
                transform: scale(1.1);
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
              }

              &:active {
                transform: scale(0.95);
              }
            `}
          >
            Soy Zico
          </button>
        </div>

        <div
          className={css`
            position: relative;
          `}
        >
          <Tooltip title="Un Zicofyer es un consultante" placement="top">
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                color: '#37474f',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                padding: '4px',
              }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <button
            onClick={() => navigate('/paciente')}
            className={css`
              padding: 20px 40px;
              font-size: 1.5rem;
              font-weight: bold;
              color: #ffffff;
              background-color: #d3f36f;
              border: none;
              border-radius: 12px;
              box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
              text-transform: uppercase;

              &:hover {
                background-color: #b4dd4e;
                transform: scale(1.1);
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
              }

              &:active {
                transform: scale(0.95);
              }
            `}
          >
            Soy Zicofyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserType;