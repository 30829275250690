import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const GradientBackground = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6)',
  backgroundSize: '180% 180%',
  animation: 'gradient-animation 24s ease infinite',
  '@keyframes gradient-animation': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
});

const AnimatedBox = styled(Box)({
  textAlign: 'center',
  animation: 'fadeIn 2s ease-in-out',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
});

const ThankYouPage = () => {
  const { width, height } = useWindowSize();

  return (
    <GradientBackground>
      <Confetti width={width} height={height} />
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>
        <AnimatedBox>
          <CheckCircleIcon style={{ fontSize: 80, color: 'green', marginBottom: '20px' }} />
          <Typography variant="h3" component="h1" gutterBottom>
            ¡Gracias por completar tu conversación!
          </Typography>
          <Typography variant="body1">
            Tu conversación fue procesada y enviada a tu Zico. En los próximos días se estará contactando contigo para continuar con el proceso.
          </Typography>
        </AnimatedBox>
      </Container>
    </GradientBackground>
  );
};

export default ThankYouPage;