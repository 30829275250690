import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';

function TermsDialog({ showTerms, handleTermsClose, handleTermsAccept, userData }) {
  return (
    <Dialog open={showTerms} onClose={handleTermsClose} maxWidth="md" fullWidth>
      <DialogTitle>Términos y Condiciones</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Documento de Consentimiento para Psicólogos Participantes en el Proyecto ZicoAsistente</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Introducción:</strong><br />
            Este documento tiene como objetivo informarle sobre su participación en el proyecto ZicoAsistente, desarrollado por Zicofy. Este proyecto de investigación está diseñado para explorar el potencial de un sistema de inteligencia artificial que brinde asistencia psicológica virtual, buscando optimizar y enriquecer la práctica profesional de la psicología.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Identificación del Responsable del Tratamiento de Datos</strong><br />
            Responsable: Agustín Rodríguez<br />
            Dirección: Calle 366, Número 544 Berazategui, Buenos Aires, Argentina | CP 1886<br />
            Correo electrónico: agus@zicofy.com<br />
            Teléfono: +34 602 483 029
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Descripción del Proyecto:</strong><br />
            El proyecto ZicoAsistente tiene como fin desarrollar y evaluar un sistema de inteligencia artificial que funcione como apoyo en el tratamiento psicológico, facilitando una asistencia continua a los Zicofyers y ofreciendo a los psicólogos información valiosa sobre el progreso del tratamiento. Este proyecto se encuentra en una fase experimental y su participación es fundamental para evaluar la eficacia y seguridad del sistema.
          </Typography>
          <Typography variant="body2">
            <strong>Datos Personales Recolectados:</strong>
          </Typography>
          <ul>
            <li>Identificadores personales y profesionales (nombre, edad, credenciales profesionales).</li>
            <li>Información de contacto (correo electrónico, teléfono).</li>
            <li>Datos relacionados con la interacción y el uso del sistema ZicoAsistente.</li>
            <li>Retroalimentación sobre la funcionalidad y la utilidad del sistema.</li>
          </ul>
          <Typography variant="body2">
            <strong>Uso de los Datos:</strong><br />
          </Typography>
          <ul>
            <li>Analizar y mejorar la eficacia del sistema de inteligencia artificial.</li>
            <li>Evaluar la integración del sistema en prácticas psicológicas convencionales.</li>
            <li>Investigar la aceptación del sistema por parte de los profesionales de la psicología.</li>
            <li>Desarrollar mejoras en base a su retroalimentación directa.</li>
          </ul>
          <Typography variant="body2" paragraph>
            <strong>Retención de Datos y Derechos bajo el GDPR:</strong><br />
            Los datos serán retenidos durante el período necesario para cumplir con los objetivos mencionados, tras lo cual serán seguramente eliminados. Como parte de sus derechos bajo el GDPR, usted tiene derecho a acceder, rectificar, suprimir y portar sus datos, así como a retirar su consentimiento en cualquier momento.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Medidas de Seguridad:</strong><br />
            Implementamos medidas de seguridad técnicas y organizativas robustas para proteger sus datos personales, incluyendo cifrado de datos en tránsito y en reposo, controles de acceso estrictos y formación continua al personal sobre la importancia de la protección de datos.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Retiro del Consentimiento:</strong><br />
            Puede retirar su consentimiento en cualquier momento enviando una notificación escrita a ayuda@zicofy.com. El retiro de su consentimiento no afectará la legalidad del tratamiento de sus datos efectuado antes de dicho retiro.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Firma de Consentimiento:</strong><br />
            Yo, <strong>{userData ? `${userData.firstName} ${userData.lastName}` : 'Nombre Apellido'}</strong>,
            declaro que he leído y comprendido la información proporcionada en este documento y consiento
            voluntariamente participar en el proyecto ZicoAsistente bajo los términos aquí descritos.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTermsClose}>Cancelar</Button>
        <Button onClick={handleTermsAccept} variant="contained" color="primary">
          He leído y acepto
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TermsDialog;