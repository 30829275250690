import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import WelcomeMessage from '../components/psico/WelcomeMessage';
import PatientsTable from '../components/psico/PatientsTable';
import PlanDialog from '../components/psico/PlanDialog';
import { Box } from '@mui/material';

const PatientsList = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [loadingReport, setLoadingReport] = useState({});
  const [reportGenerated, setReportGenerated] = useState({});
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [plan, setPlan] = useState('free');
  const [userName, setUserName] = useState('');
  const [informesGenerados, setInformesGenerados] = useState(0);
  const [informeLimite, setInformeLimite] = useState(1);
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const waLink = process.env.REACT_APP_WA_LINK;
  const stripeStarterUrl = process.env.REACT_APP_STRIPE_STARTER_URL;
  const stripeProfessionalUrl = process.env.REACT_APP_STRIPE_PROFESSIONAL_URL;

  const planLimits = {
    free: 99,
    starter: 99,
    professional: 99,
    alphatester: 99,
  };

  const handleLogout = () => {
    localStorage.removeItem('idPsico');
    localStorage.clear();
    navigate('/login/login');
  };

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/dossier/get-all-documents`);
        setPatients(response.data);
      } catch (error) {
        console.error('Error al cargar los datos de los Zicofyers:', error);
      }
    };
  
    const fetchData = async () => {
      await fetchPatients();
      await fetchPsicoData();
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const idPsico = localStorage.getItem('idPsico');
    const name = localStorage.getItem('userName');
    setUserName(name || '');
  
    if (idPsico) {
      const filtered = patients.filter((patient) => patient.idPsico === idPsico);
      const limit = planLimits[plan] || 1;
      setFilteredPatients(filtered.slice(0, limit));
      setShowTable(true);
    }
  }, [patients, plan]);

  const handleGenerateReport = async (email) => {
    setLoadingReport((prev) => ({ ...prev, [email]: true }));
    try {
      const response = await axios.post(`${apiBaseUrl}/dossier/generate-and-send-assistant`, { email });
      
      if (response.status === 200) {
        setReportGenerated((prev) => ({ ...prev, [email]: true }));
        setInformesGenerados(prev => prev + 1);
        alert('Informe generado con éxito, en unos minutos lo recibirás en tu correo');
      } else if (response.status === 403) {
        alert('Has alcanzado el límite de informes generados. Por favor, actualiza tu plan para generar más informes.');
      } else {
        alert('Ocurrió un error al generar el informe. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      console.error('Error al generar el reporte:', error);
      if (error.response && error.response.status === 403) {
        alert('Has alcanzado el límite de informes generados. Por favor, actualiza tu plan para generar más informes.');
      } else {
        alert('Ocurrió un error al generar el informe. Por favor, inténtalo de nuevo más tarde.');
      }
    } finally {
      setLoadingReport((prev) => ({ ...prev, [email]: false }));
    }
  };

  const handlePlanDialogOpen = () => {
    setOpenPlanDialog(true);
  };

  const handlePlanDialogClose = () => {
    setOpenPlanDialog(false);
  };

  const handleCopyLink = () => {
    const idPsico = localStorage.getItem('idPsico');
    const link = `https://asisapp.zicofy.com/paciente?idPsico=${idPsico}`;
    navigator.clipboard.writeText(link)
      .then(() => {
        alert('Enlace copiado al portapapeles');
      })
      .catch(err => {
        console.error('Error al copiar el enlace', err);
      });
  };

  const fetchPsicoData = async () => {
    const idPsico = localStorage.getItem('idPsico');
    if (!idPsico) return;

    try {
      const response = await axios.get(`${apiBaseUrl}/dossier/psico-data`, {
        params: { idPsico },
      });

      const { informesGenerados, informeLimite, plan } = response.data;
      console.log('informesGenerados', informesGenerados);
      console.log('informeLimite', informeLimite);
      console.log('plan', plan);

      setInformesGenerados(informesGenerados || 0);
      setInformeLimite(informeLimite || 1);
      setPlan(plan || 'free');
    } catch (error) {
      console.error('Error al obtener los datos del psicólogo:', error);
    }
  };

  return (
    <div
      className={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        background: linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6);
        background-size: 180% 180%;
        animation: gradient-animation 24s ease infinite;
        padding: 20px;
        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        overflow-y: auto;
      `}
    >

      <Box component="form">
        <Box
          component="img"
          sx={{
            height: 60,
            mb: 2,
          }}
          alt="Logo"
          src="/download-1@2x.png"
        />
      </Box>

      <WelcomeMessage
        handleLogout={handleLogout}
        waLink={waLink}
        handlePlanDialogOpen={handlePlanDialogOpen}
        handleCopyLink={handleCopyLink}
        userName={userName}
        plan={plan}
        filteredPatients={filteredPatients}
        planLimits={planLimits}
        informesGenerados={informesGenerados}
        informeLimite={informeLimite}
      />

      {showTable && (
        <PatientsTable
          filteredPatients={filteredPatients}
          loadingReport={loadingReport}
          reportGenerated={reportGenerated}
          handleGenerateReport={handleGenerateReport}
        />
      )}

      <PlanDialog
        open={openPlanDialog}
        onClose={handlePlanDialogClose}
        plan={plan}
        waLink={waLink}
        stripeStarterUrl={stripeStarterUrl}
        stripeProfessionalUrl={stripeProfessionalUrl}
        maxWidth="md"
        fullWidth={true}
      />
    </div>
  );
};

export default PatientsList;