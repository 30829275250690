import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import TermsDialog from '../components/TermsDialog';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from "react-router-dom";

function PsicoLogin() {
  const { action } = useParams();
  const isRegister = action === 'register';
  const [showTerms, setShowTerms] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [animationClass, setAnimationClass] = useState('');
  const navigate = useNavigate();

  const switchToRegister = () => {
    setAnimationClass('slide-out');
    setTimeout(() => {
      navigate('/login/register');
      setAnimationClass('slide-in');
    }, 300);
  };
  
  const switchToLogin = () => {
    setAnimationClass('slide-out'); 
    setTimeout(() => {
      navigate('/login/login');
      setAnimationClass('slide-in');
    }, 300);
  };

  useEffect(() => {
    setAnimationClass('slide-in');
  }, [action]);

  const handleTermsOpen = () => setShowTerms(true);
  const handleTermsClose = () => setShowTerms(false);
  const handleTermsAccept = () => {
    setShowTerms(false);
  };

  return (
    <div className={css`
      width: 100%;
      min-height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: linear-gradient(124deg, #d3f36f, #74c2ef, #ffdaf6);
      background-size: 180% 180%;
      animation: gradient-animation 24s ease infinite;

      @keyframes gradient-animation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }

      .slide-in {
        animation: fadeIn 0.3s ease;
      }

      .slide-out {
        animation: fadeOut 0.3s ease;
      }

      @keyframes fadeIn {
        0% { opacity: 0; transform: translateY(20px); }
        100% { opacity: 1; transform: translateY(0); }
      }

      @keyframes fadeOut {
        0% { opacity: 1; transform: translateY(0); }
        100% { opacity: 0; transform: translateY(20px); }
      }
    `}>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        className={css`
          position: absolute;
          top: 20px;
          left: 20px;
          background-color: rgba(255, 255, 255, 0.7);
          color: #333;
          &:hover {
            background-color: rgba(255, 255, 255, 0.9);
          }
        `}
      >
        Volver al inicio
      </Button>
      <Box
        className={animationClass}
        sx={{
          width: '100%',
          maxWidth: isRegister ? '500px' : '400px',
          padding: '30px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '10px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        {isRegister ? (
          <RegisterForm switchToLogin={switchToLogin} handleTermsOpen={handleTermsOpen} setUserData={setUserData} />
        ) : (
          <LoginForm switchToRegister={switchToRegister} setUserId={setUserId} />
        )}
      </Box>

      <TermsDialog showTerms={showTerms} handleTermsClose={handleTermsClose} handleTermsAccept={handleTermsAccept} userData={userData} />
    </div>
  );
}

export default PsicoLogin;