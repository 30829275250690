import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LoginForm from "./pages/LoginForm";
import ThankYouPage from "./pages/ThankYouPage";
import Chat from "./pages/Chat";
import PatientsList from "./pages/patientsList";
import PsicoLogin from "./pages/psicoLogin";
import UserType from "./pages/userType";
import initializeGA from "./analytics";
import ReactGA from 'react-ga4';
import Err404 from './pages/err404';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

initializeGA();

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const { setPathPageView, setIdentity, setTrackPageView } = useTrackingCode();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
    setPathPageView(pathname);
    setTrackPageView();
  }, [pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Inicio - ZicoAsis";
        metaDescription = "Accede a ZicoAsis, tu asistente de Inteligencia Artificial.";
        break;
      case "/login":
        title = "Login - ZicoAsis";
        metaDescription = "Accede a ZicoAsis, tu asistente de Inteligencia Artificial.";
        break;
      case "/thank-you-page":
        title = "Gracias - ZicoAsis";
        metaDescription = "Gracias por completar tu sesión con ZicoAsis.";
        break;
      case "/chat":
        title = "Chat con ZicoAsis";
        metaDescription = "Interacciona con ZicoAsis, tu asistente de Inteligencia Artificial.";
        break;
      case "/psicologo":
        title = "Lista de Zicofyers - ZicoAsis";
        metaDescription = "Consulta la lista de Zicofyers registrados en ZicoAsis.";
        break;
      case "/register":
        title = "Registro - ZicoAsis";
        metaDescription = "Regístrate en ZicoAsis para acceder a tu asistente de Inteligencia Artificial.";
        break;
      default:
        title = "ZicoAsis - Asistente de Inteligencia Artificial";
        metaDescription = "Bienvenido a ZicoAsis, tu asistente de Inteligencia Artificial.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      } else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'description';
        metaTag.content = metaDescription;
        document.head.appendChild(metaTag);
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<UserType />} />
      <Route path="/thank-you-page" element={<ThankYouPage />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/psicologo" element={<PatientsList/>} />
      <Route path="/login/:action" element={<PsicoLogin />} />
      <Route path="/paciente" element={<LoginForm />} />
      <Route path="*" element={<Err404 />} />
    </Routes>
  );
}
export default App;